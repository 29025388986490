import React from "react";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlagUsa, faPhone, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faCanadianMapleLeaf} from '@fortawesome/free-brands-svg-icons';
import { Link } from "react-router-dom";
import trainerheadimg from "../assets/trainers/headimg.png";
import trainer1 from '../assets/trainers/1.png';
import trainer2 from '../assets/trainers/2.jpg';
import trainer3 from '../assets/trainers/3.jpg';
import trainer4 from '../assets/trainers/4.jpeg';
import trainer5 from '../assets/trainers/5.jpg';
import trainer6 from '../assets/trainers/6.jpeg';
import trainer7 from '../assets/trainers/7.jpg';

const Trainers = () => {
    return (
      <>
        <Header />
        <div className="breadscrumbg">
          <div className="pagewidth">
            <div className="row">
              <div className="col-md-8">
                <p
                  className="breadscrumb-pagetitle trainer-breadscrumb-pagetitle"
                  style={{ borderBottom: 0 }}
                >
                  Join as a Trainer with Upskills Certifications
                </p>
                <p className="breadscrumb-text trainer-breadscrumb-text">
                  Empowering Organizations with Future-Ready Corporate Training
                  Solutions
                </p>
                <p className="trainer-breadscrumb-description">
                  Prepare to take the next step in your career with the leading
                  training provider globally. Begin your immersive learning
                  journey with us.
                </p>
                <Link to={"/"} className="course-btn trainer-breadscrumb-btn">
                  <p>Join as a Trainer</p>
                </Link>
              </div>
              <div className="col-md-4">
                <img src={trainerheadimg} className="trainerheadimg" />
              </div>
            </div>
          </div>
        </div>
        <div className="home-section2 about-section1">
          <div className="pagewidth">
            <p className="section-head">Our Trainers</p>
            <div className="trainer-row-section">
              <div className="trainer-left-border">
                <img src={trainer1} className="lefttrainerimg"></img>
                <p className="lefttrainer-name">Stephen Mercer</p>
                <p className="lefttrainer-degree">(PMP, LSS, CAPM, CPMP, Agile/Scrum)</p>
                <p className="righttrainer-desc deskhide">
                Stephen Mercer is a highly qualified professional with expertise in project management, process consulting, and various related disciplines. With 24 years of experience as a process consultant and project manager, Stephen brings a wealth of knowledge to his role. He teaches courses such as PMP, LSSGB, Project Management Fundamentals, CAPM, and Agile/Scrum Methodology. Additionally, Stephen holds certifications in process mapping, information systems auditing, senior advising, financial planning, investment advising, and FEMA disaster coordination. His diverse qualifications and practical experience make him a valuable asset for individuals and organizations seeking to improve their project management practices and streamline processes.
                </p>
              </div>
              <div className="trainer-right-border">
                <p className="righttrainer-desc">
                Stephen Mercer is a highly qualified professional with expertise in project management, process consulting, and various related disciplines. With 24 years of experience as a process consultant and project manager, Stephen brings a wealth of knowledge to his role. He teaches courses such as PMP, LSSGB, Project Management Fundamentals, CAPM, and Agile/Scrum Methodology. Additionally, Stephen holds certifications in process mapping, information systems auditing, senior advising, financial planning, investment advising, and FEMA disaster coordination. His diverse qualifications and practical experience make him a valuable asset for individuals and organizations seeking to improve their project management practices and streamline processes.
                </p>
              </div>
            </div>

            <div className="trainer-row-section">
              <div className="trainer-left-border">
                <img src={trainer6} className="lefttrainerimg"></img>
                <p className="lefttrainer-name">Todd Lowe</p>
                <p className="lefttrainer-degree">(PMP, LSSBB)</p>
                <p className="righttrainer-desc deskhide">
                Todd Lowe, a seasoned executive with extensive global experience in Manufacturing, Operations, and Distribution for Public/PE firms, offers comprehensive training in Operational Excellence, Capacity Analysis, Site Rationalization, Strategic Planning, Business Turnaround, Change Management, Talent Acquisition, Process Improvement & Optimization. His expertise includes FPA, Capital Planning, Supply Chain Optimization, ERP, LEAN, Six Sigma, and Industry 4.0 technologies. Todd’s training programs focus on building world-class teams, fostering a culture of continuous improvement, and equipping professionals with the skills needed to achieve and sustain competitive advantage in today’s dynamic business landscape.
                </p>
              </div>
              <div className="trainer-right-border">
                <p className="righttrainer-desc">
                Todd Lowe, a seasoned executive with extensive global experience in Manufacturing, Operations, and Distribution for Public/PE firms, offers comprehensive training in Operational Excellence, Capacity Analysis, Site Rationalization, Strategic Planning, Business Turnaround, Change Management, Talent Acquisition, Process Improvement & Optimization. His expertise includes FPA, Capital Planning, Supply Chain Optimization, ERP, LEAN, Six Sigma, and Industry 4.0 technologies. Todd’s training programs focus on building world-class teams, fostering a culture of continuous improvement, and equipping professionals with the skills needed to achieve and sustain competitive advantage in today’s dynamic business landscape.
                </p>
              </div>
            </div>

            <div className="trainer-row-section">
              <div className="trainer-left-border">
                <img src={trainer5} className="lefttrainerimg"></img>
                <p className="lefttrainer-name">Christopher Blado</p>
                <p className="lefttrainer-degree">(PMP, CAPM )</p>
                <p className="righttrainer-desc deskhide">
                Christopher Blado, a Seattle-based professional, holds degrees in English, International Studies, and Public Administration, complemented by certifications in conflict resolution, project management and strategic research. With over 15 years of experience spanning non-profit, healthcare, and government sectors, Christopher excels in contract negotiation, financial analysis, and strategic planning.  Outside work, Christopher enjoys travel, hiking, camping, improv comedy, and playing guitar. With a commitment to excellence and a passion for exploration, he continues to make impactful contributions to his field and his community. 
                </p>
              </div>
              <div className="trainer-right-border">
                <p className="righttrainer-desc">
                Christopher Blado, a Seattle-based professional, holds degrees in English, International Studies, and Public Administration, complemented by certifications in conflict resolution, project management and strategic research. With over 15 years of experience spanning non-profit, healthcare, and government sectors, Christopher excels in contract negotiation, financial analysis, and strategic planning.  Outside work, Christopher enjoys travel, hiking, camping, improv comedy, and playing guitar. With a commitment to excellence and a passion for exploration, he continues to make impactful contributions to his field and his community. 
                </p>
              </div>
            </div>

            <div className="trainer-row-section">
              <div className="trainer-left-border">
                <img src={trainer7} className="lefttrainerimg"></img>
                <p className="lefttrainer-name">Jennylyn Quilidro</p>
                <p className="lefttrainer-degree">(PMP®, PMI-ACP®, Prince2 Practitioner, SAFe® 6 Scrum Master, and SAFe® 6 Practice Consultant)</p>
                <p className="righttrainer-desc deskhide">
                Jennylyn or Jen is a seasoned project management professional with a passion for shaping the next generation of project managers. With over 14 years of extensive experience in project and program management across diverse industries, Jen brings a wealth of knowledge and expertise to her role as a course instructor at Red River College.
                <br />
                <br />
                Jen’s journey in project management has been marked by a series of remarkable achievements and leadership roles. As a certified PMP®, PMI-ACP®, Prince2 Practitioner, SAFe® 6 Scrum Master, and SAFe® 6 Practice Consultant, she possesses a comprehensive understanding of agile methodologies and project management best practices.
                <br />
                <br />
                Throughout her career, Jen has excelled in various capacities, including serving as a Scrum Master for Test and Release Management teams, overseeing the transition from Waterfall to Agile methodologies, and facilitating the adoption of SAFe Framework. Her proficiency in utilizing tools such as JIRA and Confluence has been instrumental in streamlining project execution and collaboration.
                <br />
                <br />
                Beyond her technical skills, Jen’s ability to lead, coach, and mentor teams has been evident in her roles as a Sr. Project Manager, Sr. Consultant, and PMO Manager. She has successfully managed complex projects, implemented ERP solutions, and driven strategic initiatives to fruition, all while fostering a culture of continuous improvement and innovation.
                <br />
                <br />
                With a commitment to excellence and a dedication to sharing her knowledge and experiences, Jen is committed to empowering aspiring project managers to succeed in their careers. Through her dynamic teaching style and real-world insights, she inspires students to embrace the challenges of project management and emerge as confident and capable leaders in their field.
                </p>
              </div>
              <div className="trainer-right-border">
                <p className="righttrainer-desc ">
                Jennylyn or Jen is a seasoned project management professional with a passion for shaping the next generation of project managers. With over 14 years of extensive experience in project and program management across diverse industries, Jen brings a wealth of knowledge and expertise to her role as a course instructor at Red River College.
                <br />
                <br />
                Jen’s journey in project management has been marked by a series of remarkable achievements and leadership roles. As a certified PMP®, PMI-ACP®, Prince2 Practitioner, SAFe® 6 Scrum Master, and SAFe® 6 Practice Consultant, she possesses a comprehensive understanding of agile methodologies and project management best practices.
                <br />
                <br />
                Throughout her career, Jen has excelled in various capacities, including serving as a Scrum Master for Test and Release Management teams, overseeing the transition from Waterfall to Agile methodologies, and facilitating the adoption of SAFe Framework. Her proficiency in utilizing tools such as JIRA and Confluence has been instrumental in streamlining project execution and collaboration.
                <br />
                <br />
                Beyond her technical skills, Jen’s ability to lead, coach, and mentor teams has been evident in her roles as a Sr. Project Manager, Sr. Consultant, and PMO Manager. She has successfully managed complex projects, implemented ERP solutions, and driven strategic initiatives to fruition, all while fostering a culture of continuous improvement and innovation.
                <br />
                <br />
                With a commitment to excellence and a dedication to sharing her knowledge and experiences, Jen is committed to empowering aspiring project managers to succeed in their careers. Through her dynamic teaching style and real-world insights, she inspires students to embrace the challenges of project management and emerge as confident and capable leaders in their field.
                </p>
              </div>
            </div>

            <div className="trainer-row-section">
              <div className="trainer-left-border">
                <img src={trainer4} className="lefttrainerimg"></img>
                <p className="lefttrainer-name">Eshwar Jakkula</p>
                <p className="lefttrainer-degree">(SPC 6.0, Kanban and DevOps Coach)</p>
                <p className="righttrainer-desc deskhide">
                Eshwar Jakkula is a distinguished Agile and SAFe expert with over 15 years of experience in leading transformations across multinational corporations and government agencies. Eshwar is a highly sought-after SAFe trainer who has empowered over 4,000 individuals through 225+ classes covering SAFe versions 4.0 to 6.0 in all the courses.
                <br />
                <br />
                He conducts executive-level workshops on SAFe, feature and user story writing, and technical workshops on TDD, BDD, and CICD implementation. Eshwar’s practical insights and proven strategies bridge the gap between theory and practice, consistently delivering tangible results through his classes. As a SAFe exam developer and subject matter expert, he has contributed to the development of half of the SAFe courses. Don’t miss the opportunity to learn from a trainer who combines unparalleled expertise with a passion for empowering individuals and organizations to achieve their full potential in Agile and SAFe.
                </p>
              </div>
              <div className="trainer-right-border">
                <p className="righttrainer-desc">
                Eshwar Jakkula is a distinguished Agile and SAFe expert with over 15 years of experience in leading transformations across multinational corporations and government agencies. Eshwar is a highly sought-after SAFe trainer who has empowered over 4,000 individuals through 225+ classes covering SAFe versions 4.0 to 6.0 in all the courses.
                <br />
                <br />
                He conducts executive-level workshops on SAFe, feature and user story writing, and technical workshops on TDD, BDD, and CICD implementation. Eshwar’s practical insights and proven strategies bridge the gap between theory and practice, consistently delivering tangible results through his classes. As a SAFe exam developer and subject matter expert, he has contributed to the development of half of the SAFe courses. Don’t miss the opportunity to learn from a trainer who combines unparalleled expertise with a passion for empowering individuals and organizations to achieve their full potential in Agile and SAFe.
                </p>
              </div>
            </div>

            <div className="trainer-row-section">
              <div className="trainer-left-border">
                <img src={trainer3} className="lefttrainerimg"></img>
                <p className="lefttrainer-name">Todd Williams</p>
                <p className="lefttrainer-degree">(SPC6, PMP, TBR, CPIP)</p>
                <p className="righttrainer-desc deskhide">
                Todd Williams is an accomplished leader with a wealth of experience in driving Agile practices and spearheading transformational initiatives. Currently serving as the Director of Agile Practice and Chief Product Owner for the Enterprise Data Office at WESCO Distribution, he holds a pivotal role in shaping the organization’s Agile journey and ensuring alignment with business goals and customer needs.
                <br />
                <br />
                With a comprehensive background in Agile methodologies, Todd is committed to driving innovation and efficiency across the organization. He oversees the Product Owner Cycle, providing invaluable support to Agile teams and leaders, while also managing and coaching a team of Agile Coaches and Scrum Masters. As a thought leader in Product Innovation and Customer Discovery, he continuously strives to push the boundaries of what’s possible, driving the company’s success in a rapidly evolving market.
                </p>
              </div>
              <div className="trainer-right-border">
                <p className="righttrainer-desc">
                Todd Williams is an accomplished leader with a wealth of experience in driving Agile practices and spearheading transformational initiatives. Currently serving as the Director of Agile Practice and Chief Product Owner for the Enterprise Data Office at WESCO Distribution, he holds a pivotal role in shaping the organization’s Agile journey and ensuring alignment with business goals and customer needs.
                <br />
                <br />
                With a comprehensive background in Agile methodologies, Todd is committed to driving innovation and efficiency across the organization. He oversees the Product Owner Cycle, providing invaluable support to Agile teams and leaders, while also managing and coaching a team of Agile Coaches and Scrum Masters. As a thought leader in Product Innovation and Customer Discovery, he continuously strives to push the boundaries of what’s possible, driving the company’s success in a rapidly evolving market.
                </p>
              </div>
            </div>

            <div className="trainer-row-section">
              <div className="trainer-left-border">
                <img src={trainer2} className="lefttrainerimg"></img>
                <p className="lefttrainer-name">Alex Rohrs</p>
                <p className="lefttrainer-degree">(SPC6, PMP )</p>
                <p className="righttrainer-desc deskhide">
                Alex is a problem solver and recognized leader with a deep passion for Agile and SAFe and helping individuals, teams and organizations achieve better results by identifying the right market problems to solve and implementing smarter ways of working. He has a background in Program and Product Management and spent over 15 years in the educational technology field.
                <br />
                He is expert in managing cross-functional teams from conception through product launch, including implementation and management of processes designed to identify value, streamline efficiency, improve quality and bring products to market quickly and efficiently. Alex has led Agile transformations at multiple organizations and successfully implemented SAFe at Scholastic, leading 3 Agile Release Trains through 14 PI’s resulting in the release of industry leading Reading and Math intervention programs for the K-12 market.
                </p>
              </div>
              <div className="trainer-right-border">
                <p className="righttrainer-desc">
                Alex is a problem solver and recognized leader with a deep passion for Agile and SAFe and helping individuals, teams and organizations achieve better results by identifying the right market problems to solve and implementing smarter ways of working. He has a background in Program and Product Management and spent over 15 years in the educational technology field.
                <br />
                He is expert in managing cross-functional teams from conception through product launch, including implementation and management of processes designed to identify value, streamline efficiency, improve quality and bring products to market quickly and efficiently. Alex has led Agile transformations at multiple organizations and successfully implemented SAFe at Scholastic, leading 3 Agile Release Trains through 14 PI’s resulting in the release of industry leading Reading and Math intervention programs for the K-12 market.
                </p>
              </div>
            </div>

          </div>
        </div>
        <Footer />
      </>
    );
}

export default Trainers;