import React from 'react';

export const Imprints = () => {
    return (
        <div className="home-section3 light-bg">
          <div className="pagewidth">
            <p className="section-head">Imprints of UPSKILLSCERT!</p>
            <div className="imprintsdiv">
              <div className="row">
                <div className="col-6 col-sm-3">
                  <div className="imprintsinglediv">
                    <p className="imprintCount">50 +</p>
                    <p className="imprintText">Curated Courses</p>
                  </div>
                </div>
                <div className="col-6 col-sm-3">
                  <div className="imprintsinglediv">
                    <p className="imprintCount">15K+</p>
                    <p className="imprintText">Students Enrolled</p>
                  </div>
                </div>
                <div className="col-6 col-sm-3">
                  <div className="imprintsinglediv">
                    <p className="imprintCount">30+</p>
                    <p className="imprintText">Countries</p>
                  </div>
                </div>
                <div className="col-6 col-sm-3">
                  <div className="imprintsinglediv">
                    <p className="imprintCount">100+</p>
                    <p className="imprintText">Trainers</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
}