import React from "react";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFlagUsa,
  faPhone,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { faCanadianMapleLeaf } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import { CourseCard } from "../components/CourseCard";
import img1 from '../assets/corporate/1.png';
import client1 from '../assets/corporate/client1.png';
import client2 from '../assets/corporate/client2.png';
import client3 from '../assets/corporate/client3.png';
import client4 from '../assets/corporate/client4.png';
import { Imprints } from "../components/Imprints";

const CorporateTraining = () => {
  return (
    <>
      <Header />
      <div className="breadscrumbg">
        <div className="pagewidth">
          <p className="breadscrumb-pagetitle">Upskill Your Team</p>
          <div className="row">
            <div className="col-md-8">
              <p className="breadscrumb-text corporate-subtext">
                Empowering Organizations with Future Ready Corporate Training Solutions
              </p>
              <p className="corporate-breadscrumb-desc">Prepare to take the next step in your career with the leading training provider globally. Begin your immersive learning journey with us.</p>
              <div className="banner-btn-div">
                  <Link to={"/"} className="course-btn">
                    <p>View Course</p>
                  </Link>
                  <Link to={"/"} className="course-btn course-btn1">
                    <p>Book Consultation</p>
                  </Link>
                </div>
            </div>
            <div className="col-md-4">
                <img src={img1} className="corporate-img1" />
            </div>
          </div>
        </div>
      </div>
      <div className="home-section2 corporate-section1">
        <div className="pagewidth">
            <div className="row">
                <div className="col-md-7">
                    <p className="section-head corporate-section1-head">Empowering Organizations with Future-Ready Corporate Training Solutions</p>
                    <p className="choose-description corporate-section1-description">In today’s fiercely competitive landscape, technology evolves at a rapid pace, rendering skills obsolete within a short span of time. Recognizing the critical importance of continuously enhancing the abilities of employees, we are committed to fostering professional growth through our comprehensive corporate training programs. Our tailored training schedules are designed to equip individuals with the latest industry knowledge and skills, empowering them to stay ahead in their respective fields. By prioritizing the ongoing development of your workforce, we help your organization thrive in the dynamic business environment of today.</p>
                </div>
                <div className="col-md-5">
                    <p className="section-head">Our Clients</p>
                    <div className="row clientmaindiv">
                        <div className="col-6 col-md-6">
                            <div className="client-div">
                                <img src={client1} className="client1img" />
                            </div>
                        </div>
                        <div className="col-6 col-md-6">
                            <div className="client-div">
                                <img src={client2} className="client1img" />
                            </div>
                        </div>
                        <div className="col-6 col-md-6">
                            <div className="client-div">
                                <img src={client3} className="client1img" />
                            </div>
                        </div>
                        <div className="col-6 col-md-6">
                            <div className="client-div">
                                <img src={client4} className="client1img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div className="home-section2">
          <div className="pagewidth">
            <p className="section-head">
            Training and Certification Programs for Corporate Teams
            </p>
            <div className="course-card-row row">
              <div className="col-md-4">
                <CourseCard />
              </div>
              <div className="col-md-4">
                <CourseCard />
              </div>
              <div className="col-md-4">
                <CourseCard />
              </div>
              <div className="col-md-4">
                <CourseCard />
              </div>
              <div className="col-md-4">
                <CourseCard />
              </div>
              <div className="col-md-4">
                <CourseCard />
              </div>
            </div>
            <div className="course-btn2div">
              <Link to={"/"} className="course-btn course-btn2">
                <p>View All Courses</p>
              </Link>
            </div>
          </div>
        </div>
        <Imprints />
      <Footer />
    </>
  );
};

export default CorporateTraining;
