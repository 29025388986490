import React, { useEffect, useState,useCallback,
  useRef,
  StrictMode, } from "react";
import Sidebar from "../../components/Sidebar";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../../config/Config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Overlay from "../../components/Overlay";
import { useMemo } from 'react';
import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
ModuleRegistry.registerModules([ClientSideRowModelModule]);

const CourseCategories = () => {
  let navigate = useNavigate();
  const userdata = useSelector((store) => store.userReducer.userdata);
  const [coursesdata, setcoursesdata] = useState([]);
  const [showloding, setshowloding] = useState(false);
  const [showmodal, setshowmodal] = useState(false);
  const [editshowmodal, seteditshowmodal] = useState(false);
  const [categoryname, setcategoryname] = useState("");
  const [selectedid, setselectedid] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [colDefs, setColDefs] = useState([]);
  const paginationPageSizeSelector = useMemo(() => {
    return [10, 20, 50, 100];
  }, []);

  const defaultColDef = {
    flex: 1,
  };

  const DeleteCellRenderer = (props) => {
    const handleDelete = () => {
      console.log('delete >>');
    };
  
    return (
      <p 
        style={{ cursor: 'pointer', color: 'red' }} 
        onClick={handleDelete}
      >
        Test
      </p>
    );
  };

  const clickhandle = () =>  {
    console.log('Mission Launched')
  }
  
  const fetchallcourses = async () => {
    setshowloding(true);
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userdata?.token,
      },
    };
    let resp = await axios
      .get(apiUrl + "getallcoursecategories", headers)
      .then((res) => {
        if (res.data.status == "success") {
          console.log('getallcategories >>', res.data);
          var prevdata = res.data.data;
          if(prevdata?.length > 0) {
            var columnsarr = [];
            var hidecolumns = ['id', 'created_at', 'updated_at', 'sort'];
            var columns = Object.keys(prevdata[0]);
            for(var i = 0; i < columns?.length;i++) {
              if(!hidecolumns.includes(columns[i])) {
                var obj = {};
                obj.field = columns[i];
                console.log('obj >>', obj)
                columnsarr.push(obj);
              }
            }
            //delete
            var deleteobj = 
            {
              field: "delete",
              headerName: "Delete",
              cellRenderer: (props) => {
                
                return (
                  <div
                        onClick={() => {
                          deletecourse(props?.data?.id, props?.data?.categoryname);
                          // console.log('props >>', props?.data);
                        }}
                        style={{maxWidth: 35, paddingLeft: 10, paddingRight: 10, cursor: 'pointer'}}
                      >
                        <FontAwesomeIcon
                          icon={faTrash}
                          className="sidebaricons"
                          style={{ color: "red" }}
                        />
                      </div>
                )
              },
              cellRendererParams: {
                onClick: clickhandle,
              },
            }
            columnsarr.push(deleteobj);
            setColDefs(columnsarr);
            setRowData(prevdata);
          }
          
          // setcoursesdata(res.data.data);
        } else {
          // alert(res.data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
      setshowloding(false);
  };
  const deletecourse = async (id, title) => {
    const result = window.confirm("Are you sure you want " + title);
    if (result) {
      var data = JSON.stringify({
        id: id,
      });
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      let resp = await axios
        .post(apiUrl + "deletecategory", data, headers)
        .then((res) => {
          if (res.data.status == "success") {
            fetchallcourses();
          } else {
          }
        })
        .catch((err) => {
          alert(err.message);
        });
    } else {
      // User clicked Cancel or closed the dialog
      console.log("User clicked Cancel");
    }
  };
  const submithandle = async () => {
    if (categoryname) {
      setshowloding(true);
      var data = {
        name: categoryname,
      };
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      let resp = await axios
        .post(apiUrl + "addnewmaincategory", data, headers)
        .then((res) => {
          // console.log('res >>>', res.data)
          if (res.data.status == "success") {
            setshowmodal(false);
            setcategoryname("");
            fetchallcourses();
            setshowloding(false);
          } else {
            alert(res.data.message);
            setshowloding(false);
          }
        })
        .catch((err) => {
          setshowloding(false);
          alert(err.message);
        });
      setshowloding(false);
    } else {
      alert("Empty Field");
    }
  };

  const editcategoryname = async(id, name) => {
    setselectedid(id);
    setcategoryname(name);
    seteditshowmodal(true);
  }

  const submithandle1 = async () => {
    if (categoryname) {
      setshowloding(true);
      var data = {
        id: selectedid,
        name: categoryname,
      };
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      let resp = await axios
        .post(apiUrl + "editmaincategory", data, headers)
        .then((res) => {
          // console.log('res >>>', res.data)
          if (res.data.status == "success") {
            seteditshowmodal(false);
            setselectedid(0);
            setcategoryname("");
            fetchallcourses();
            setshowloding(false);
          } else {
            alert(res.data.message);
            setshowloding(false);
          }
        })
        .catch((err) => {
          setshowloding(false);
          alert(err.message);
        });
      setshowloding(false);
    } else {
      alert("Empty Field");
    }
  };

 
  useEffect(() => {
    if (userdata && Object.keys(userdata).length > 0) {
      //   fetchdata();
      fetchallcourses();
    } else {
      navigate("/newadminlogin");
    }
  }, []);
  return (
    <div className="HomeMain">
        <Overlay showloader={showloding} />
      <div className="sidebar-div">
        <Sidebar name={"Admin"} />
      </div>
      <div className="main-layout">
        <div className="mainDiv">
          <p className="oneplaceHead">CATEGORIES LIST</p>
          <br />
          <div className="tablebuttondiv tablebuttondivpage">
            <Link
              onClick={() => {
                setcategoryname("");
                setshowmodal(true);
              }}
              className="btndiv webadminmaindiv"
            >
              <p>{"Add Category"}</p>
            </Link>
          </div>
          <table class="table table-bordered" style={{display: 'none'}}>
            <thead>
              <tr>
                <th style={{ minWidth: 100 }}>Sr No.</th>
                <th>Category Name</th>
                <th>Category Slug</th>
                {/* <th>Edit</th> */}
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {coursesdata?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.categoryname}</td>
                    <td>{item.slug}</td>
                    {/* <td>
                        <div
                            onClick={() => {
                              editcategoryname(item.id, item.categoryname);
                            }}
                          >
                            <FontAwesomeIcon
                          icon={faPenToSquare}
                          className="sidebaricons"
                        />
                          </div>
                    </td> */}
                    <td>
                      <div
                        onClick={() => {
                          deletecourse(item.id, item.categoryname);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faTrash}
                          className="sidebaricons"
                          style={{ color: "red" }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div>
          {/* <MaterialReactTable
          columns={columns}
          data={data}
    /> */}
          </div>

          <div
      className={
        "ag-theme-quartz"
      }
      style={{ width: "100%", height: 550 }}
    >
      <AgGridReact
      // modules={AllModules}
        rowData={rowData}
        columnDefs={colDefs}
        defaultColDef={defaultColDef}
        suppressRowClickSelection={true}
        groupSelectsChildren={true}
        rowSelection={"multiple"}
        pagination={true}
        paginationPageSize={10}
        paginationPageSizeSelector={paginationPageSizeSelector}
        // onGridReady={onGridReady}
      />
    </div>

          

          {/*Add modal */}
          <Modal
            show={showmodal}
            onHide={() => setshowmodal(false)}
            size="md"
            className="custom-width-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Main Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="webadmin-modalmainbody">
                <div className="">
                  <label>Name</label>
                  <input
                    value={categoryname}
                    onChange={(t) => {
                      setcategoryname(t.target.value);
                    }}
                    type="text"
                    className="form-control tabinput"
                    placeholder="Enter Category name"
                  />
                </div>
                <div
                  className="webadmin-modalbtn"
                  onClick={() => {
                    submithandle();
                  }}
                >
                  <p>Submit</p>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/*Edit modal */}
          <Modal
            show={editshowmodal}
            onHide={() => seteditshowmodal(false)}
            size="md"
            className="custom-width-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit Main Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="webadmin-modalmainbody">
                <div className="">
                  <label>Name</label>
                  <input
                    value={categoryname}
                    onChange={(t) => {
                      setcategoryname(t.target.value);
                    }}
                    type="text"
                    className="form-control tabinput"
                    placeholder="Enter Category name"
                  />
                </div>
                <div
                  className="webadmin-modalbtn"
                  onClick={() => {
                    submithandle1();
                  }}
                >
                  <p>Update</p>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default CourseCategories;
