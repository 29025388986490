import React from 'react';
import { Route, Link, BrowserRouter as Router, Routes } from 'react-router-dom';
import payment from '../assets/payment/payments-logo.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook,faTwitter, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons';

export const Footer = () => {
    return (
        <div className='footermaindiv'>
            <div className="pagewidth">
                <div className='footerinnermaindiv'>
                    <div className='footerdiv1'>
                        <p className='footerhead'>Company</p>
                        <div className='footerlinksdiv'>
                            <Link to={'/'}>About Us</Link>
                            <Link to={'/'}>Learner Reviews</Link>
                            <Link to={'/'}>Contact Us</Link>
                            <Link to={'/'}>Sitemap</Link>
                        </div>
                    </div>
                    <div className='footerdiv1'>
                        <p className='footerhead'>Explore</p>
                        <div className='footerlinksdiv'>
                            <Link to={'/'}>Corporate Group Training</Link>
                            <Link to={'/'}>Join as a Trainer</Link>
                            <Link to={'/'}>Blog</Link>
                            <Link to={'/'}>Brand Assets</Link>
                        </div>
                    </div>
                    <div className='footerdiv1'>
                        <p className='footerhead'>Popular Courses</p>
                        <div className='footerlinksdiv'>
                            <Link to={'/'}>Overview</Link>
                            <Link to={'/'}>Design</Link>
                            <Link to={'/'}>Code</Link>
                            <Link to={'/'}>Collaborate</Link>
                        </div>
                    </div>
                    <div className='footerdiv1'>
                        <p className='footerhead'>Get in Touch</p>
                        <div className='footerlinksdiv'>
                            <Link><FontAwesomeIcon icon={faFacebook} className='footericons' /> Facebook</Link>
                            <Link to={'/'}><FontAwesomeIcon icon={faTwitter} className='footericons' /> Twitter</Link>
                            <Link to={'/'}><FontAwesomeIcon icon={faYoutube} className='footericons' /> YouTube</Link>
                            <Link to={'/'}><FontAwesomeIcon icon={faInstagram} className='footericons' /> Instagram</Link>
                        </div>
                    </div>
                    <div className='footerdiv1'>
                        <p className='footerhead'>Secure Payments</p>
                        <div className='footerlinksdiv'>
                            <div className='payment-strip'>
                                <img src={payment} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='copyrightdiv'>
                    <p className='copyrighttext'>Copyright 2023 © Upskills Certifications LLC. All rights Reserved.</p>
                </div>
            </div>
        </div>
    )
}