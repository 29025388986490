import React, { useEffect, useState } from "react";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { json, useParams } from "react-router-dom";
import img1 from "../assets/courses/1.png";
import img2 from "../assets/courses/2.webp";
import img3 from "../assets/courses/3.webp";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesRight,
  faHandPointRight,
  faChevronDown,
  faChevronUp,
  faBriefcase,
  faXmark,
  faCalendar,
  faClock,
  faDollar,
} from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { Rating } from "react-simple-star-rating";
import axios from "axios";
import { apiUrl } from "../config/Config";
import Overlay from "../components/Overlay";
import Spinner from "react-activity/dist/Spinner";
import "bootstrap/dist/css/bootstrap.min.css";
import { Carousel } from "react-bootstrap";
import Modal from "react-modal";

const CourseDetails = () => {
  const { categoryslug, courseslug } = useParams();
  const [forceupdatestate, setforceupdatestate] = useState(false);
  const [samplemodulesdata, setsamplemodulesdata] = useState([]);
  const [faqdata, setfaqdata] = useState([]);
  const [reveiewsdata, setreveiewsdata] = useState([]);
  const [trainersdata, settrainersdata] = useState([]);
  const [coursedetails, setcoursedetails] = useState({});
  const [showloding, setshowloding] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const [countriesdropdown, setcountriesdropdown] = useState([]);
  const [selectedcountry, setselectedcountry] = useState(null);
  const [citiesdropdown, setcitiesdropdown] = useState([]);
  const [selectedcity, setselectedcity] = useState(null);
  const [monthsdropdown, setmonthsdropdown] = useState([]);
  const [selectedmonth, setselectedmonth] = useState(null);
  const [inpersonbatchesdata, setinpersonbatchesdata] = useState([]);
  const [shownobatchesavailable, setshownobatchesavailable] = useState(false);

  const [selectedcountry1, setselectedcountry1] = useState(null);
  const [selectedmonth1, setselectedmonth1] = useState(null);
  const [liveonlinebatchesdata, setliveonlinebatchesdata] = useState([]);
  const [shownobatchesavailable1, setshownobatchesavailable1] = useState(false);

  let navigate = useNavigate();

  const handletogglecard = (index) => {
    var prevdata = samplemodulesdata;
    var newdata = [];
    for (var i = 0; i < prevdata.length; i++) {
      if (i == index) {
        var obj = {};
        obj = prevdata[i];
        obj.isshow = !obj.isshow;
        newdata.push(obj);
      } else {
        var obj = {};
        obj = prevdata[i];
        obj.isshow = false;
        newdata.push(obj);
      }
    }
    setsamplemodulesdata(newdata);
    setforceupdatestate(!forceupdatestate);
  };
  const handletogglecard1 = (index) => {
    var prevdata = faqdata;
    var newdata = [];
    for (var i = 0; i < prevdata.length; i++) {
      if (i == index) {
        var obj = {};
        obj = prevdata[i];
        obj.isshow = !obj.isshow;
        newdata.push(obj);
      } else {
        var obj = {};
        obj = prevdata[i];
        obj.isshow = false;
        newdata.push(obj);
      }
    }
    setfaqdata(newdata);
    setforceupdatestate(!forceupdatestate);
  };
  const fetchcoursedetails = async (catslug, courslug) => {
    window.scrollTo(0, 0);
    setshowloding(true);
    // console.log('categoryslug >>',categoryslug);
    // console.log('courseslug >>',courseslug);
    var data = JSON.stringify({
      category: catslug,
      slug: courslug,
    });
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let resp = await axios
      .post(apiUrl + "getcoursedetail", data, headers)
      .then((res) => {
        // console.log('res >>>', res.data.data);
        setcoursedetails(res.data.data);
        setcountriesdropdown(res.data.countries);
        if (res.data.data?.coursemodules) {
          setsamplemodulesdata(JSON.parse(res.data.data?.coursemodules));
        } else {
          setsamplemodulesdata([]);
        }
        if (res.data.data?.faqs) {
          setfaqdata(JSON.parse(res.data.data?.faqs));
        } else {
          setfaqdata([]);
        }
        if (res.data.data?.reviews) {
          setreveiewsdata(JSON.parse(res.data.data?.reviews));
        } else {
          setreveiewsdata([]);
        }
        if (res.data.data?.trainers) {
          settrainersdata(JSON.parse(res.data.data?.trainers));
        } else {
          settrainersdata([]);
        }

        setshowloding(false);
      })
      .catch((err) => {
        alert(err.message);
      });
  };
  const DisplayOnlyText = (data) => {
    const parser = new DOMParser();
    var datas = data;
    const htmlString = data;
    // for p tags
    //  const cleanedHtmlString = datas.replace(/<ul>[\s\S]*?<\/ul>/g, '');
    return (
      <div dangerouslySetInnerHTML={{ __html: htmlString }} />
      //  <div className="">
      //      <div dangerouslySetInnerHTML={{ __html: cleanedHtmlString }} />
      //  </div>
    );
  };
  const DisplayTextLI = (data) => {
    const parser = new DOMParser();
    var datas = data;
    const htmlString = data;

    // for p tags
    // const cleanedHtmlString = datas.replace(/<ul>[\s\S]*?<\/ul>/g, '');

    // for li tags
    const doc = parser.parseFromString(datas, "text/html");
    const liElements = doc.querySelectorAll("li");
    const liTextContent = Array.from(liElements).map((li) => li.textContent);

    // setLiContent(liTextContent);
    return (
      // <div dangerouslySetInnerHTML={{ __html: htmlString }} />
      <div className="row">
        <div className="col-12 col-sm-6">
          {liTextContent?.length > 0
            ? liTextContent?.map((item, index) => {
                if (index == 0 || index % 2 == 0) {
                  return (
                    <div className="coursedetailsfeatures-row">
                      <FontAwesomeIcon
                        icon={faAnglesRight}
                        className="coursedetailsicons"
                      />
                      <p className="choose-description coursedetailsfeatures-desc">
                        {item}
                      </p>
                    </div>
                  );
                }
              })
            : null}
        </div>
        <div className="col-12 col-sm-6">
          {liTextContent?.length > 0
            ? liTextContent?.map((item, index) => {
                if (index > 0 && index % 2 !== 0) {
                  return (
                    <div className="coursedetailsfeatures-row">
                      <FontAwesomeIcon
                        icon={faAnglesRight}
                        className="coursedetailsicons"
                      />
                      <p className="choose-description coursedetailsfeatures-desc">
                        {item}
                      </p>
                    </div>
                  );
                }
              })
            : null}
        </div>
      </div>
    );
  };
  const DisplayTextSchedulesLI = (data) => {
    const parser = new DOMParser();
    var datas = data;
    const htmlString = data;

    // for p tags
    // const cleanedHtmlString = datas.replace(/<ul>[\s\S]*?<\/ul>/g, '');

    // for li tags
    const doc = parser.parseFromString(datas, "text/html");
    const liElements = doc.querySelectorAll("li");
    const liTextContent = Array.from(liElements).map((li) => li.textContent);

    // setLiContent(liTextContent);
    return (
      // <div dangerouslySetInnerHTML={{ __html: htmlString }} />
      <>
        {liTextContent?.length > 0
          ? liTextContent?.map((item, index) => {
              return (
                <div className="coursedetailsfeatures-row">
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    className="coursedetailsicons"
                  />
                  <p className="choose-description coursedetailsfeatures-desc">
                    {item}
                  </p>
                </div>
              );
            })
          : null}
      </>
    );
  };
  const DisplayTextBoth = (data) => {
    const parser = new DOMParser();
    var datas = data;
    const htmlString = data;

    // for p tags
    const cleanedHtmlString = datas?.replace(/<ul>[\s\S]*?<\/ul>/g, "");

    // for li tags
    const doc = parser.parseFromString(datas, "text/html");
    const liElements = doc?.querySelectorAll("li");
    const liTextContent = Array.from(liElements).map((li) => li.textContent);

    // setLiContent(liTextContent);
    return (
      // <div dangerouslySetInnerHTML={{ __html: htmlString }} />
      <div className="">
        <p className="choose-description coursedetails-whicanattend">
          <div dangerouslySetInnerHTML={{ __html: cleanedHtmlString }} />
        </p>
        <div className="coursedetails-whicanattend-pointsidv">
          {liTextContent?.length > 0
            ? liTextContent?.map((item, index) => {
                return (
                  <div className="coursedetailsfeatures-row">
                    <FontAwesomeIcon
                      icon={faHandPointRight}
                      className="coursedetailsicons"
                    />
                    <p className="choose-description coursedetailsfeatures-desc">
                      {item}
                    </p>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    );
  };
  const opendownloadbrochure = () => {
    var link = JSON.parse(coursedetails?.uploadbrochure)?.link;
    // console.log('opendownloadbrochure >>>', link)
    window.open(link, "_blank");
  };
  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  const openModal1 = () => {
    setModalIsOpen1(true);
  };
  const closeModal1 = () => {
    setModalIsOpen1(false);
  };
  const fetchinpersoncities = async (country) => {
    var data = JSON.stringify({
      country: country,
    });
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let resp = await axios
      .post(apiUrl + "getinpersoncities", data, headers)
      .then((res) => {
        // console.log('res >>>', res.data.data);
        setcitiesdropdown(res.data.data);
        setshowloding(false);
      })
      .catch((err) => {
        alert(err.message);
      });
  };
  const filterinpersonbatches = async () => {
    // console.log('selectedcountry >>', selectedcountry);
    // console.log('selectedcity >>', selectedcity);
    // console.log('selectedmonth >>', selectedmonth);
    setshownobatchesavailable(false);
    var splitdate = selectedmonth?.split("--");
    // console.log('splitdate >>', splitdate);
    var data = JSON.stringify({
      country: selectedcountry,
      city: selectedcity,
      fromdate: splitdate[0],
      enddate: splitdate[1],
      // course: courseslug,
      course: "PMP-Certification-Training",
    });
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let resp = await axios
      .post(apiUrl + "getinpersonbatches", data, headers)
      .then((res) => {
        console.log("res >>>", res.data.data);
        setinpersonbatchesdata(res.data.data);
        if (res.data.data?.length == 0) {
          setshownobatchesavailable(true);
        } else {
          setshownobatchesavailable(false);
        }
        // setcitiesdropdown(res.data.data);
        setshowloding(false);
      })
      .catch((err) => {
        alert(err.message);
      });
  };
  const InpersonSearchbtn = () => {
    var diablestatus = true;
    if (selectedcountry && selectedcity && selectedmonth) {
      diablestatus = false;
    } else {
      diablestatus = true;
    }
    return (
      <button
        type="button"
        className="btn btn-primary modalsearchbtn"
        onClick={() => filterinpersonbatches()}
        disabled={diablestatus}
      >
        Search
      </button>
    );
  };

  const filterliveOnlinebatches = async () => {
    setshownobatchesavailable(false);
    var splitdate = selectedmonth1?.split("--");
    var data = JSON.stringify({
      country: selectedcountry1,
      fromdate: splitdate[0],
      enddate: splitdate[1],
      // course: courseslug,
      course: "PMP-Certification-Training",
    });
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let resp = await axios
      .post(apiUrl + "getliveonlinebatches", data, headers)
      .then((res) => {
        console.log("res >>>", res.data.data);
        setliveonlinebatchesdata(res.data.data);
        if (res.data.data?.length == 0) {
          setshownobatchesavailable1(true);
        } else {
          setshownobatchesavailable1(false);
        }
        setshowloding(false);
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const LiveOnlineSearchbtn = () => {
    var diablestatus = true;
    if (selectedcountry1 && selectedmonth1) {
      diablestatus = false;
    } else {
      diablestatus = true;
    }
    return (
      <button
        type="button"
        className="btn btn-primary modalsearchbtn"
        onClick={() => filterliveOnlinebatches()}
        disabled={diablestatus}
      >
        Search
      </button>
    );
  };

  const formatMonthYear = (date) => {
    const options = { month: "long", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  // Helper function to get the start and end dates of a month
  const getMonthStartEndDates = (date) => {
    const start = new Date(date.getFullYear(), date.getMonth(), 1);
    const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return {
      startDate: start.toISOString().split("T")[0],
      endDate: end.toISOString().split("T")[0],
    };
  };

  // Function to get the current month and the next 7 months with start and end dates
  const getNextMonths = () => {
    const months = [];
    const currentDate = new Date();

    for (let i = 0; i <= 7; i++) {
      const futureDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + i,
        1
      );
      const monthYear = formatMonthYear(futureDate);
      const { startDate, endDate } = getMonthStartEndDates(futureDate);
      var obj = {};
      obj["date"] = startDate + "--" + endDate;
      obj["month"] = monthYear;
      // console.log('startDate >>', startDate);
      // console.log('endDate >>', endDate);
      months.push(obj);
    }

    return months;
  };

  const handleonlineselfstudyclick = () => {
    navigate("/cart", {
      state: {
        categoryslug: categoryslug,
        courseslug: courseslug,
        coursename: coursedetails?.title,
        price: coursedetails?.onlineselfofferprice,
        type: "Online self study",
        country: "",
        city: "",
        startdate: "",
        enddate: "",
        starttime: "",
        endtime: "",
        weektype: "",
      },
    });
  };

  const handleinpersonclassroomclick = (data) => {
    console.log("data >>", data);
    closeModal();
    navigate("/cart", {
      state: {
        categoryslug: categoryslug,
        courseslug: courseslug,
        coursename: coursedetails?.title,
        price: data?.offerprice,
        type: "In Person Classroom",
        country: data?.country,
        city: data?.city,
        startdate: data?.startdate,
        enddate: data?.enddate,
        starttime: data?.starttime,
        endtime: data?.endtime,
        weektype: data?.weektype,
      },
    });
  };

  const handleliveonlineclassroom = (data) => {
    console.log("handleliveonlineclassroom data >>", data);
    closeModal1();
    navigate("/cart", {
      state: {
        categoryslug: categoryslug,
        courseslug: courseslug,
        coursename: coursedetails?.title,
        price: data?.offerprice,
        type: "Live Online Classroom",
        country: data?.country,
        city: data?.city,
        startdate: data?.startdate,
        enddate: data?.enddate,
        starttime: data?.starttime,
        endtime: data?.endtime,
        weektype: data?.weektype,
      },
    });
  };

  const scrollsection = (id) => {
    const section = document.querySelector('#'+id);
    window.scrollTo({
      top: section.offsetTop - 110, // Adjust the offset as needed
      behavior: 'smooth'
    });
  }

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  useEffect(() => {
    // setselectedcountry()
    // console.log('selectedcountry >>', selectedcountry);
    if (selectedcountry) {
      fetchinpersoncities(selectedcountry);
    }
  }, [selectedcountry]);

  useEffect(() => {
    fetchcoursedetails(categoryslug, courseslug);
  }, [courseslug]);

  useEffect(() => {
    const nextMonths = getNextMonths();
    console.log("nextMonths >>>", nextMonths);
    setmonthsdropdown(nextMonths);
    // setMonths(nextMonths);
  }, []);
  return (
    <div>
      <Header />
      {/* <Overlay showloader={showloding} /> */}
      {showloding ? (
        <div className="defaultsectionheight">
          <Spinner color="#4fb293" size={40} className="spinnerCenter1" />
        </div>
      ) : (
        <>
          <div className="course-details-topborder"></div>
          <div className="coursedetails-section1">
            <div className="pagewidth">
              <div className="row">
                <div className="col-md-8">
                  <p className="section-head coursedetails-coursetitle">
                    {coursedetails?.title}
                  </p>
                  <p className="choose-description coursedetails-description">
                    {DisplayOnlyText(coursedetails?.headparagh)}
                  </p>
                  <div className="section1-rating-div">
                    <div className="coursedetails-googlediv">
                      <img src={img2} className="course-detail-img2" />
                      <p className="ratinggooglediv section-head">
                        {coursedetails?.googlerating}/5
                      </p>
                    </div>
                    <div className="coursedetails-googlediv coursedetails-googlediv1">
                      <img src={img3} className="course-detail-img2" />
                      <p className="ratinggooglediv section-head">
                        {coursedetails?.trustrating}/5
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <img src={img1} className="course-detail-img1" />
                </div>
              </div>
            </div>
          </div>
          <div className="coursedetailsnavbar mobhide">
            <div className="pagewidth">
              <nav className="navbar navbar-inverse">
                <ul className="nav navbar-nav">
                  <li className="coursedetails-navlink" >
                    <Link to={""}  onClick={() => {scrollsection('overview')}}>Overview</Link>
                  </li>
                  <li className="coursedetails-navlink" >
                    <Link to={""} onClick={() => {scrollsection('curriculum')}}>Curriculum</Link>
                  </li>
                  <li className="coursedetails-navlink">
                    <Link to="" onClick={() => {scrollsection('schedules')}}>Schedules</Link>
                  </li>
                  {
                    trainersdata?.length > 0 ?
                    <li className="coursedetails-navlink">
                    <Link to="" onClick={() => {scrollsection('trainers')}}>Trainers</Link>
                  </li> : null
                  }

                  {
                    faqdata?.length > 0 ?
                    <li className="coursedetails-navlink">
                    <Link to="" onClick={() => {scrollsection('faqs')}}>FAQ's</Link>
                  </li> : null
                  }

                  {
                    reveiewsdata?.length > 0 ?
                    <li className="coursedetails-navlink">
                    <Link to="" onClick={() => {scrollsection('reviews')}}>Reviews</Link>
                  </li> : null
                  }
                  
                  
                  
                  <li className="coursedetails-navlink">
                    <Link to="" onClick={() => {scrollsection('corporatetrainings')}}>Corporate Trainings</Link>
                  </li>
                  
                </ul>
              </nav>
            </div>
          </div>
          <section id="overview">
          <div className="home-section2 coursedetails-section3" >
            <div className="pagewidth">
              <div className="row">
                <div className="col-md-8">
                  <p className="section-head coursedetails-coursetitle">
                    Course Overview
                  </p>
                  <p className="choose-description coursedetails-description">
                    {DisplayOnlyText(coursedetails?.courseoveriew)}
                  </p>
                  <br />
                  <br />
                  <p className="section-head coursedetails-coursetitle">
                    What Is {coursedetails?.title} ?
                  </p>
                  <p className="choose-description coursedetails-description">
                    {DisplayOnlyText(coursedetails?.coursedescription)}
                  </p>
                  <br />
                  <br />
                  <p className="section-head coursedetails-coursetitle">
                    Key Features Of {coursedetails?.title}
                  </p>
                  <div className="coursedetails-featuresbg">
                    {DisplayTextLI(coursedetails?.keyfeatures)}
                    {/* <div className="row">
                  <div className="col-12 col-sm-6">
                    <div className="coursedetailsfeatures-row">
                      <FontAwesomeIcon
                        icon={faAnglesRight}
                        className="coursedetailsicons"
                      />
                      <p className="choose-description coursedetailsfeatures-desc">
                        4 days of online training/live interactive sessions
                      </p>
                    </div>
                    <div className="coursedetailsfeatures-row">
                      <FontAwesomeIcon
                        icon={faAnglesRight}
                        className="coursedetailsicons"
                      />
                      <p className="choose-description coursedetailsfeatures-desc">
                        The experimental and real-time based learning format
                        based on the latest PMBOK guide
                      </p>
                    </div>
                    <div className="coursedetailsfeatures-row">
                      <FontAwesomeIcon
                        icon={faAnglesRight}
                        className="coursedetailsicons"
                      />
                      <p className="choose-description coursedetailsfeatures-desc">
                        Training held by highly qualified, certified, and
                        accredited trainers
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="coursedetailsfeatures-row">
                      <FontAwesomeIcon
                        icon={faAnglesRight}
                        className="coursedetailsicons"
                      />
                      <p className="choose-description coursedetailsfeatures-desc">
                        Earn 35 contact hours/PDUs
                        <br />
                        <br />
                      </p>
                    </div>
                    <div className="coursedetailsfeatures-row">
                      <FontAwesomeIcon
                        icon={faAnglesRight}
                        className="coursedetailsicons"
                      />
                      <p className="choose-description coursedetailsfeatures-desc">
                        Get access to high-quality online or digital resources
                        curated by experienced trainers of PMI
                      </p>
                    </div>
                    <div className="coursedetailsfeatures-row">
                      <FontAwesomeIcon
                        icon={faAnglesRight}
                        className="coursedetailsicons"
                      />
                      <p className="choose-description coursedetailsfeatures-desc">
                        24/7 learning assistance and support
                      </p>
                    </div>
                  </div>
                </div> */}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="features-right-div">
                    <div className="whocanattenddiv">
                      <p>Who Can Attend</p>
                    </div>
                    {DisplayTextBoth(coursedetails?.attenddescription)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          </section>
          <section id="curriculum">
          <div className="home-section2 coursedetails-section4">
            <div className="pagewidth">
              <p className="section-head coursedetails-coursetitle">
                Course Curriculum
              </p>
              <div className="">
                {coursedetails?.uploadbrochure ? (
                  <div
                    onClick={() => {
                      opendownloadbrochure();
                    }}
                    className="whocanattenddiv downloadbrochurebtn"
                  >
                    <p>Download Brochure</p>
                  </div>
                ) : null}
              </div>
              <div className="coursedetails-modulesmaindiv">
                {samplemodulesdata?.length > 0
                  ? samplemodulesdata?.map((item, index) => {
                      // console.log("samplemodulesdata item >>", item);
                      return (
                        <div className="coursedetails-card">
                          <div
                            onClick={() => {
                              handletogglecard(index);
                            }}
                            className="coursedetails-cardheader"
                          >
                            {item.isshow ? (
                              <FontAwesomeIcon
                                icon={faChevronUp}
                                className="coursedetailscardicons"
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faChevronDown}
                                className="coursedetailscardicons"
                              />
                            )}

                            <p>{item.title}</p>
                          </div>
                          {item.isshow ? (
                            <div className="coursedetails-cardbody">
                              <p className="choose-description modules-description">
                                {DisplayOnlyText(item?.description)}
                              </p>
                            </div>
                          ) : null}
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
          </section>
          <section id="schedules">
          <div className="home-section2 coursedetails-section5">
            <div className="pagewidth">
              <p className="section-head coursedetails-batcheshead">
                Schedules for {coursedetails?.title}
              </p>
              <p className="choose-description coursedetails-batchessubhead">
                {DisplayOnlyText(coursedetails?.scheduledescription)}
              </p>
              <div className="coursedetails-batchesdiv">
                <div className="row">
                  <div className="col-12 col-sm-4">
                    <div className="batches-boxdiv">
                      <div className="whocanattenddiv inpersonbtn">
                        <p>In-Person Classroom</p>
                      </div>
                      <div className="coursedetails-whicanattend-pointsidv inpersonpointsdiv">
                        {DisplayTextSchedulesLI(coursedetails?.inpersonpoints)}
                        <div className="inperson-pricediv">
                          <p className="cancelledprice">
                            <del>$ {coursedetails?.personprice}</del>
                          </p>
                          <p className="offerprice">
                            $ {coursedetails?.personofferprice}
                          </p>
                        </div>
                        <div
                          className="inperson-registerbtn"
                          onClick={() => {
                            openModal();
                          }}
                        >
                          <p>Register Now</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="batches-boxdiv">
                      <div className="whocanattenddiv inpersonbtn">
                        <p>Live Online Classroom</p>
                      </div>
                      <div className="coursedetails-whicanattend-pointsidv inpersonpointsdiv">
                        {DisplayTextSchedulesLI(
                          coursedetails?.liveonlinepoints
                        )}
                        <div className="inperson-pricediv">
                          <p className="cancelledprice">
                            <del>$ {coursedetails?.liveonlineprice}</del>
                          </p>
                          <p className="offerprice">
                            $ {coursedetails?.liveonlineofferprice}
                          </p>
                        </div>
                        <div
                          className="inperson-registerbtn"
                          onClick={() => {
                            openModal1();
                          }}
                        >
                          <p>Register Now</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="batches-boxdiv">
                      <div className="whocanattenddiv inpersonbtn">
                        <p>Online Self Study</p>
                      </div>
                      <div className="coursedetails-whicanattend-pointsidv inpersonpointsdiv">
                        {DisplayTextSchedulesLI(
                          coursedetails?.onlineselfpoints
                        )}
                        <div className="inperson-pricediv">
                          <p className="cancelledprice">
                            <del>$ {coursedetails?.onlineselfprice}</del>
                          </p>
                          <p className="offerprice">
                            $ {coursedetails?.onlineselfofferprice}
                          </p>
                        </div>
                        <div
                          className="inperson-registerbtn"
                          onClick={() => {
                            handleonlineselfstudyclick();
                          }}
                        >
                          <p>Register Now</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </section>

          <section id="trainers">
          {trainersdata?.length > 0 ? (
            <div className="home-section2 coursedetails-section4">
              <div className="pagewidth">
                <p className="section-head ">Meet Our Trainers</p>
              </div>
              <br />
              <Carousel indicators={true}>
              {
                trainersdata?.length > 0 ?
                trainersdata?.map((item, index) => {
                  return (
                    <Carousel.Item>
                      <div className="trainersinglediv">
                <div className="singlecourse-trainerdiv">
                  <div className="row">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-8">
                      <div className="trainer-rightdiv">
                        <p className="trainername">
                          {item?.name} <br />
                          <span className="trainerspecialize">
                            ({item?.degree})
                          </span>
                        </p>
                        
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <img
                        src={item?.image?.link}
                        alt={item?.name}
                        className={"trainer-img trainer-imgcoursedetails"}
                      />
                    </div>
                    <div className="col-sm-8">
                      <div className="trainer-rightdiv">  
                        <p className="singlecourse-overview-description">
                        {DisplayOnlyText(item.description)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                    </Carousel.Item>
                    
                  )
                }) : null
              }
              </Carousel>
              
              
            </div>
          ) : null}
          </section>
          <section id="faqs">
            {
              faqdata?.length ?
              <div className="home-section2 coursedetails-section4">
            <div className="pagewidth">
              <p className="section-head ">
                Frequently Asked Questions about {coursedetails?.title}
              </p>
              <br />
              <div className="coursedetails-modulesmaindiv">
                {faqdata?.length > 0
                  ? faqdata?.map((item, index) => {
                      // console.log("item >>", item);
                      return (
                        <div className="coursedetails-card">
                          <div
                            onClick={() => {
                              handletogglecard1(index);
                            }}
                            className="coursedetails-cardheader"
                          >
                            {item.isshow ? (
                              <FontAwesomeIcon
                                icon={faChevronUp}
                                className="coursedetailscardicons"
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faChevronDown}
                                className="coursedetailscardicons"
                              />
                            )}

                            <p>{item.title}</p>
                          </div>
                          {item.isshow ? (
                            <div className="coursedetails-cardbody">
                              <p className="choose-description">
                                {DisplayOnlyText(item.description)}
                              </p>
                            </div>
                          ) : null}
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          </div> : null
            }
          
          </section>
          <section id="reviews">
          {reveiewsdata?.length > 0 ? (
            <div className="home-section2 coursedetails-section4">
              <div className="pagewidth">
                <p className="section-head ">
                  Our Learners Reviews from Trustpilot
                </p>
              </div>
              <br />
              <br />
              <Carousel indicators={true}>
                {reveiewsdata?.map((item, index) => {
                  return (
                    <Carousel.Item>
                      <div className="coursedetails-testimonialdiv">
                        <p className="coursedetails-testiname">{item?.name}</p>
                        <Rating
                          readonly
                          initialValue={item?.rating}
                          iconsCount={5}
                          size={24}
                          fillColor={"#0AB67B"}
                        />
                        <p className="coursedetails-testicountry">
                          {item?.country}
                        </p>
                        <p className="choose-description coursedetails-desc">
                          {item?.description}
                        </p>
                      </div>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </div>
          ) : null}
          </section>
          <section id="corporatetrainings">
          <div className="home-section2 coursedetails-section6">
            <div className="pagewidth">
              <p className="section-head">
                Upskill Your Teams with our Corporate Group Trainings
              </p>
              <div className="whocanattenddiv inpersonbtn corportegroup">
                <p>
                  <FontAwesomeIcon
                    icon={faBriefcase}
                    className="corporateicon"
                  />{" "}
                  Corporate Enquiry
                </p>
              </div>
            </div>
          </div>
          </section>
          
          

          

          
          

          

          {/*In Person modals */}

          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Example Modal"
            id="inpersommodalid"
          >
            <div className="inpersonmodal">
              <div className="modalheader">
                <p className="modaltitle">
                  In-Person Classroom Batches For {coursedetails?.title}
                </p>
                <div
                  className="modalclosediv"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  <FontAwesomeIcon
                    icon={faXmark}
                    className="coursecloseicons"
                  />
                </div>
              </div>
              <div className="modalbody">
                <div className="modalbatchfiltersection">
                  <div className="row">
                    <div className="col-md-3">
                      <p for="sel1" className="form-label modalformlabel">
                        Select Country
                      </p>
                      <select
                        className="form-select"
                        id="sel1"
                        name="sellist1"
                        value={selectedcountry}
                        onChange={(t) => {
                          setselectedcountry(t.target.value);
                        }}
                      >
                        <option disabled selected>
                          Select Country
                        </option>
                        {countriesdropdown?.length > 0
                          ? countriesdropdown?.map((item, index) => {
                              return (
                                <option value={item?.country}>
                                  {item?.country}
                                </option>
                              );
                            })
                          : null}
                      </select>
                    </div>
                    <div className="col-md-3">
                      <p for="sel1" className="form-label modalformlabel">
                        Select City
                      </p>
                      <select
                        className="form-select"
                        id="sel1"
                        name="sellist1"
                        value={selectedcity}
                        onChange={(t) => {
                          setselectedcity(t.target.value);
                        }}
                      >
                        <option disabled selected>
                          Select City
                        </option>
                        {citiesdropdown?.length > 0
                          ? citiesdropdown?.map((item, index) => {
                              return (
                                <option value={item?.city}>{item?.city}</option>
                              );
                            })
                          : null}
                      </select>
                    </div>
                    <div className="col-md-3">
                      <p for="sel1" className="form-label modalformlabel">
                        Select Month
                      </p>
                      <select
                        className="form-select"
                        id="sel1"
                        name="sellist1"
                        value={selectedmonth}
                        onChange={(t) => {
                          setselectedmonth(t.target.value);
                        }}
                      >
                        <option disabled selected>
                          Select Month
                        </option>
                        {monthsdropdown?.length > 0
                          ? monthsdropdown?.map((item, index) => {
                              return (
                                <option value={item.date}>{item.month}</option>
                              );
                            })
                          : null}
                      </select>
                    </div>
                    <div className="col-md-3">
                      <label for="course">&nbsp;</label>
                      {InpersonSearchbtn()}
                    </div>
                  </div>
                  <div className="resultsdiv">
                    <p className="batchesavailablehead">Batches Available</p>
                    {inpersonbatchesdata?.length > 0 ? (
                      inpersonbatchesdata?.map((item, index) => {
                        // console.log('item >>', item);
                        return (
                          <div className="inpersondisplayrow">
                            <div className="row">
                              <div className="col-12 col-md-4">
                                <div className="popupdate">
                                  <FontAwesomeIcon
                                    icon={faCalendar}
                                    className="popupicons"
                                  />
                                  <p className="popupdatetext">
                                    {item?.startdate} - {item?.enddate}
                                  </p>
                                </div>
                                <div className="popupdate popupcountry">
                                  <p className="popupdatetext popupcountrytext">
                                    Country :
                                  </p>
                                  <p className="popupdatetext popupdatetext1">
                                    {item?.country}
                                  </p>
                                </div>
                              </div>
                              <div className="col-12 col-md-3">
                                <div className="popupdate">
                                  <FontAwesomeIcon
                                    icon={faClock}
                                    className="popupicons"
                                  />
                                  <p className="popupdatetext">
                                    {item?.starttime} - {item?.endtime}
                                  </p>
                                </div>
                                <div className="popupdate popupcountry">
                                  <p className="popupdatetext popupcountrytext">
                                    City :
                                  </p>
                                  <p className="popupdatetext popupdatetext1">
                                    {item?.city}
                                  </p>
                                </div>
                              </div>
                              <div className="col-12 col-md-3">
                                <div className="popupdate">
                                  <FontAwesomeIcon
                                    icon={faDollar}
                                    className="popupicons"
                                  />
                                  <p
                                    className="popupdatetext"
                                    style={{ marginLeft: 5 }}
                                  >
                                    USD <del>{item?.price}</del>
                                    <span className="popupofferprice">
                                      {item?.offerprice}
                                    </span>
                                  </p>
                                </div>
                                <div className="popupdate popupcountry">
                                  <p className="popupdatetext popupcountrytext">
                                    {item?.weektype}
                                  </p>
                                </div>
                              </div>
                              <div
                                className="col-12 col-md-2"
                                onClick={() => {
                                  handleinpersonclassroomclick(item);
                                }}
                              >
                                <button
                                  href="cart"
                                  className="form-control btn btn-success popupenrollbtn"
                                >
                                  Enroll
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : shownobatchesavailable ? (
                      <p className="nobatchestext">
                        No batches available for selected month.
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          {/* Live Online modals */}

          <Modal
            isOpen={modalIsOpen1}
            onRequestClose={closeModal1}
            contentLabel="Example Modal"
            id="inpersommodalid"
          >
            <div className="inpersonmodal">
              <div className="modalheader">
                <p className="modaltitle">
                  Live Online Classroom Batches For {coursedetails?.title}
                </p>
                <div
                  className="modalclosediv"
                  onClick={() => {
                    closeModal1();
                  }}
                >
                  <FontAwesomeIcon
                    icon={faXmark}
                    className="coursecloseicons"
                  />
                </div>
              </div>
              <div className="modalbody">
                <div className="modalbatchfiltersection">
                  <div className="row">
                    <div className="col-md-4">
                      <p for="sel1" className="form-label modalformlabel">
                        Select Country
                      </p>
                      <select
                        className="form-select"
                        id="sel1"
                        name="sellist1"
                        value={selectedcountry1}
                        onChange={(t) => {
                          setselectedcountry1(t.target.value);
                        }}
                      >
                        <option disabled selected>
                          Select Country
                        </option>
                        {countriesdropdown?.length > 0
                          ? countriesdropdown?.map((item, index) => {
                              return (
                                <option value={item?.country}>
                                  {item?.country}
                                </option>
                              );
                            })
                          : null}
                      </select>
                    </div>
                    <div className="col-md-4">
                      <p for="sel1" className="form-label modalformlabel">
                        Select Month
                      </p>
                      <select
                        className="form-select"
                        id="sel1"
                        name="sellist1"
                        value={selectedmonth1}
                        onChange={(t) => {
                          setselectedmonth1(t.target.value);
                        }}
                      >
                        <option disabled selected>
                          Select Month
                        </option>
                        {monthsdropdown?.length > 0
                          ? monthsdropdown?.map((item, index) => {
                              return (
                                <option value={item.date}>{item.month}</option>
                              );
                            })
                          : null}
                      </select>
                    </div>
                    <div className="col-md-3">
                      <label for="course">&nbsp;</label>
                      {LiveOnlineSearchbtn()}
                    </div>
                  </div>
                  <div className="resultsdiv">
                    <p className="batchesavailablehead">Batches Available</p>
                    {liveonlinebatchesdata?.length > 0 ? (
                      liveonlinebatchesdata?.map((item, index) => {
                        // console.log('item >>', item);
                        return (
                          <div className="inpersondisplayrow">
                            <div className="row">
                              <div className="col-12 col-md-4">
                                <div className="popupdate">
                                  <FontAwesomeIcon
                                    icon={faCalendar}
                                    className="popupicons"
                                  />
                                  <p className="popupdatetext">
                                    {item?.startdate} - {item?.enddate}
                                  </p>
                                </div>
                                <div className="popupdate popupcountry">
                                  <p className="popupdatetext popupcountrytext">
                                    Country :
                                  </p>
                                  <p className="popupdatetext popupdatetext1">
                                    {item?.country}
                                  </p>
                                </div>
                              </div>
                              <div className="col-12 col-md-3">
                                <div className="popupdate">
                                  <FontAwesomeIcon
                                    icon={faClock}
                                    className="popupicons"
                                  />
                                  <p className="popupdatetext">
                                    {item?.starttime} - {item?.endtime}
                                  </p>
                                </div>
                                {/* <div className="popupdate popupcountry">
                            <p className="popupdatetext popupcountrytext">
                            City :
                            </p>
                            <p className="popupdatetext popupdatetext1">
                            {item?.city}
                            </p>
                          </div> */}
                              </div>
                              <div className="col-12 col-md-3">
                                <div className="popupdate">
                                  <FontAwesomeIcon
                                    icon={faDollar}
                                    className="popupicons"
                                  />
                                  <p
                                    className="popupdatetext"
                                    style={{ marginLeft: 5 }}
                                  >
                                    USD <del>{item?.price}</del>
                                    <span className="popupofferprice">
                                      {item?.offerprice}
                                    </span>
                                  </p>
                                </div>
                                <div className="popupdate popupcountry">
                                  <p className="popupdatetext popupcountrytext">
                                    {item?.weektype}
                                  </p>
                                </div>
                              </div>
                              <div
                                className="col-12 col-md-2"
                                onClick={() => {
                                  handleliveonlineclassroom(item);
                                }}
                              >
                                <button
                                  href="cart"
                                  className="form-control btn btn-success popupenrollbtn"
                                >
                                  Enroll
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : shownobatchesavailable1 ? (
                      <p className="nobatchestext">
                        No batches available for selected month.
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </>
      )}
      <Footer />
    </div>
  );
};

export default CourseDetails;
