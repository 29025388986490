import React, { useState } from "react";
import logoimg from "../assets/logo.png";
import { Link } from "react-router-dom";
import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faAngleRight,
  faAngleUp
} from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';

const Sidebar = ({ name }) => {
  //   const userdata = useSelector((store) => store.userReducer.userdata);
  const [showcategoriesdropdown, setshowcategoriesdropdown] = useState(false);
  const [showenquiresfropdown, setshowenquiresfropdown] = useState(false);
  const dispatch = useDispatch(); 
  let navigate = useNavigate();
  const logoutpressed = () => {
    dispatch({ type: "userdata", payload: {} })
        navigate('/newadminlogin');
  }
  return (
    <div className="sidebar-main webadmin-sidebarmain">
      <img src={logoimg} className="sidebarLogo" />
      <div className="sidebarMenuDiv">
        <ul>
          <li>
            <Link to={'/webadmin/categorylist'} className="webadmin-sidebar-maintitle">Course Category</Link>
          </li>

          <li>
            <div className="webadmin-sidebar-maintitlediv" onClick={() => {
            setshowcategoriesdropdown(!showcategoriesdropdown);
          }}>
            <a className="webadmin-sidebar-maintitle">Courses</a>
            {
              showcategoriesdropdown ?
              <FontAwesomeIcon icon={faAngleUp} className="sidebaricons" /> :
              <FontAwesomeIcon icon={faAngleDown} className="sidebaricons" />
            }
            
          </div>
            <div
              class="dropdown-container"
              style={
                showcategoriesdropdown
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <Link to="/webadmin/addnewcourse" className="webadmin-sidebar-subtitle">Add Course</Link> 
              <Link to="/webadmin/courselist" className="webadmin-sidebar-subtitle">Courses List</Link>
            </div>
          </li>

          <li>
            <Link to={'/webadmin/locationslist'} className="webadmin-sidebar-maintitle">Locations</Link>
          </li>

          <li>
            <Link to={'/webadmin/batcheslist'} className="webadmin-sidebar-maintitle">Batches</Link>
          </li>

          <li>
            <Link to={'/webadmin/coupons'} className="webadmin-sidebar-maintitle">Coupons</Link>
          </li>

          <li>
            <div className="webadmin-sidebar-maintitlediv" onClick={() => {
            setshowenquiresfropdown(!showenquiresfropdown);
          }}>
            <a className="webadmin-sidebar-maintitle">Enquires</a>
            {
              showenquiresfropdown ?
              <FontAwesomeIcon icon={faAngleUp} className="sidebaricons" /> :
              <FontAwesomeIcon icon={faAngleDown} className="sidebaricons" />
            }
            
          </div>
            <div
              class="dropdown-container"
              style={
                showenquiresfropdown
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <Link to="/webadmin/talktoexperts" className="webadmin-sidebar-subtitle">Talk to experts</Link> 
              <Link to="/webadmin/dropaquery" className="webadmin-sidebar-subtitle">Drop a query</Link>
              <Link to="/webadmin/quotationrequest" className="webadmin-sidebar-subtitle">Quotation request</Link>
            </div>
          </li>

          <li>
            <Link to={'/webadmin/orders'} className="webadmin-sidebar-maintitle">Orders</Link>
          </li>
          
          <li>
          <div className="webadmin-sidebar-maintitlediv" onClick={() => {
           logoutpressed()
          }}>
            <a className="webadmin-sidebar-maintitle">Logout</a>
            
          </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
