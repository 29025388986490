import React from "react";
import Spinner from "react-activity/dist/Spinner";

const Overlay = ({showloader}) => {
    return (
        <>
            {
                showloader ?
                <>
                    <div className="overlay-main mobhide">
                    <Spinner color='#4fb293' size={40} className='spinnerCenter1' />
                </div>
                <div className="overlay-main deskhide">
                    <Spinner color='#4fb293' size={20} className='spinnerCenter1' />
                </div>
                </>
                 : null
            }
        </>
    )
}

export default Overlay;