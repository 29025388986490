import { createReducer } from '@reduxjs/toolkit'

const initialState = {
    userdata : null,
    isLogin: true,
    tokens: null,
    mapingdata: null,
    currTeamObj: null,
    notification: null,
    loginresponse: null,
    // module: {template_id:'1',template_name:"Sales",is_active:true}
    module: { id: '2', template_name: 'Collection' }
    // module: {template_id:1,template_name:"Debt Collection",is_active:true}  
  }

  export const userReducer = createReducer(initialState, (builder) => {
    builder.addMatcher((action) => {return action.type === "userdata"}, (state, action) => {
        state.userdata = action.payload;
    })
  })