import React from "react";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { Imprints } from "../components/Imprints";
import { Link } from "react-router-dom";
import { CourseCard } from "../components/CourseCard";
import choose1 from "../assets/choose/choose1.png";
import choose2 from "../assets/choose/choose2.png";
import choose3 from "../assets/choose/choose3.png";
import trainer1 from "../assets/trainers/1.png";
import trainer2 from "../assets/trainers/2.jpg";
import trainer3 from "../assets/trainers/3.jpg";
import trainer4 from "../assets/trainers/4.jpeg";
import trainer5 from "../assets/trainers/5.jpg";
import trainer6 from "../assets/trainers/6.jpeg";
import { Carousel } from 'react-bootstrap';
import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faAngleRight,
  faAngleUp
} from "@fortawesome/free-solid-svg-icons";


const Home = () => {
    return (
      <>
        <Header />
        <div className="bannermaindiv">
          <div className="banneroverlaycolor">
            <div className="pagewidth">
              <div className="empowering-div">
                <p className="empowering-head">
                  Empowering Professionals for Tomorrow
                </p>
                <p className="empowering-desc">
                  Prepare to take the next step in your career with the leading
                  training provider globally. Begin your immersive learning
                  journey with us.
                </p>
                <div className="banner-btn-div">
                  <Link to={"/"} className="course-btn">
                    <p>View Course</p>
                  </Link>
                  <Link to={"/"} className="course-btn course-btn1">
                    <p>Get Consultation</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-section2">
          <div className="pagewidth">
            <p className="section-head">
              Professional Certification & Training Courses
            </p>
            <div className="course-card-row row">
              <div className="col-md-4">
                <CourseCard />
              </div>
              <div className="col-md-4">
                <CourseCard />
              </div>
              <div className="col-md-4">
                <CourseCard />
              </div>
              <div className="col-md-4">
                <CourseCard />
              </div>
              <div className="col-md-4">
                <CourseCard />
              </div>
              <div className="col-md-4">
                <CourseCard />
              </div>
            </div>
            <div className="course-btn2div">
              <Link to={"/"} className="course-btn course-btn2">
                <p>View All Courses</p>
              </Link>
            </div>
          </div>
        </div>
        <Imprints />
        <div className="home-section4">
          <div className="pagewidth">
            <p className="section-head">Why Upskills Certifications ?</p>
            <div className="why-upskills-div">
              <div className="row">
                <div className="col-md-2">
                  <img src={choose1} className="chooseimg" />
                </div>
                <div className="col-md-10">
                  <p className="choose-title">Career Advancement</p>
                  <p className="choose-description">
                    Our healthy learning environment empowers you to progress at
                    your own momentum without investing in time-consuming and
                    expensive training programs. You'll get the same value and
                    your desired career advancement without making a hole in
                    your pocket. Grab the eyes of potential recruiters and let
                    your resume stand apart from the crowd.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <img src={choose2} className="chooseimg" />
                </div>
                <div className="col-md-10">
                  <p className="choose-title">Cost-effective Solutions</p>
                  <p className="choose-description">
                    Our corporate learning solutions are overall cost-effective
                    for both facilitators and learners as, on the one hand,
                    remote education cuts transportation costs, resources costs,
                    and other crucial costs drastically.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <img src={choose3} className="chooseimg" />
                </div>
                <div className="col-md-10">
                  <p className="choose-title">Learn from Anywhere Anytime</p>
                  <p className="choose-description">
                    Simpliaxis has opened a new door of success for
                    professionals by allowing them to attend web-based career
                    advancement courses. Learners can enroll in corporate
                    learning programs from anywhere globally to assist
                    organizations that run multiple branches simultaneously
                    around the globe.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-section5 light-bg">
          <div className="pagewidth">
            <p className="section-head">Our Trainers</p>
            <Carousel interval={5000} className="trainer-carousel" >
              <Carousel.Item>
              <div className="trainersmaindiv">
              <div className="trainerdiv">
                <img src={trainer1} className="trainer-img1" />
                <p className="trainer-name">Stephen Mercer</p>
              </div>
              <div className="trainerdiv">
                <img src={trainer2} className="trainer-img1" />
                <p className="trainer-name">Alex Rohrs</p>
              </div>
              <div className="trainerdiv">
                <img src={trainer3} className="trainer-img1" />
                <p className="trainer-name">Todd Williams</p>
              </div>
              <div className="trainerdiv">
                <img src={trainer4} className="trainer-img1" />
                <p className="trainer-name">Eshwar Jakkula</p>
              </div>
            </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="trainersmaindiv">
                <div className="trainerdiv">
                <img src={trainer5} className="trainer-img1" />
                <p className="trainer-name">Christopher Blado</p>
              </div>
              <div className="trainerdiv">
                <img src={trainer6} className="trainer-img1" />
                <p className="trainer-name">Todd Lowe</p>
              </div>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
        <div className="home-section6">
          <div className="pagewidth">
            <p className="section-head">Testimonials</p>
            <Carousel interval={5000}>
              <Carousel.Item>
                <div className="testidiv">
                  <p className="testitext">
                    Stephen was very knowledgeable in PMP subject matter. When
                    questions were asked, which sometimes referenced other
                    materials learned or to be learned, he was able to answer
                    them quickly and effectively. Have yet to see how his exam
                    prep questions stand up to the actual test questions, but he
                    at least got me thinking in a different way.
                  </p>
                  <p className="testiname">John Garrity</p>
                  <p className="testicountry">USA</p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="testidiv">
                  <p className="testitext">
                  I registered for certified scrum master training through Upskills Certifications and I must say the overall experience was above my expectation. The enrollment process was smooth and the two days training provided all the knowledge and insights required to pass the exam in the first attempt. All Thanks to Upskills Certifications.
                  </p>
                  <p className="testiname">Vinod Kumar</p>
                  <p className="testicountry">India</p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="testidiv">
                  <p className="testitext">
                  Stephen Mercer was my PMP instructor and he was amazing. Very detailed-oriented, organized, and clear in his teaching. His examples were helpful and he kept me engaged with the practice tests at the end. He also is clearly invested in his students in passing the exam on first try as he provided insight on the questions to look out for and provided supplemental content to ensure that we are prepared. He definitely goes above and beyond. Highly recommend!
                  </p>
                  <p className="testiname">Jaime</p>
                  <p className="testicountry">USA</p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="testidiv">
                  <p className="testitext">
                  Stephen has a tremendous amount of experience in project management, and that came through in the class. The subject matter always felt relevant to both real-life scenarios and what would be needed in order to pass the PMP exam. I enjoyed going through the questions as a group. The information that is provided through the class is very helpful.
                  </p>
                  <p className="testiname">Jason Bourg</p>
                  <p className="testicountry">USA</p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="testidiv">
                  <p className="testitext">
                  Stephen was very prompt, professional, and purposeful with his presentations. The overview was smooth and helpful. I’d recommend him any day.
                  </p>
                  <p className="testiname">Chantea Cooper</p>
                  <p className="testicountry">USA</p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="testidiv">
                  <p className="testitext">
                  I was skeptical at first. This is a new company, so I was doing a lot of research before choosing to sign up for PMP certification prep class. Well, I was not disappointed. Stephen Mercer is knowledgeable and able to answer questions that the class had effectively. The pace was great. I learned a lot from this 4-day online instructor led class.
                  </p>
                  <p className="testiname">Kay Wawro</p>
                  <p className="testicountry">Thailand</p>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
        <Footer />
      </>
    );
}

export default Home