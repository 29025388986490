import React, { useState } from "react";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlagUsa, faPhone, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faCanadianMapleLeaf} from '@fortawesome/free-brands-svg-icons';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import Spinner from "react-activity/dist/Spinner";
import { apiUrl } from "../config/Config";
import axios from 'axios';

const ContactUs = () => {
    const [fullname, setfullname] = useState("");
    const [number, setnumber] = useState("");
    const [email, setemail] = useState("");
    const [message, setmessage] = useState("");
    const [showloading, setshowloading] = useState(false);

    const handlesubmitregister = async() => {
        if(fullname && email && number ) {
            setshowloading(true);
              var data = {
                  fullname: fullname,
                  email: email,
                  number: number,
                  message: message
              };
              const headers = {
                headers: {
                  "Content-Type": "application/json",
                },
              };
              let resp = await axios
                .post(apiUrl + "contactform", data, headers)
                .then((res) => {
                  console.log('res >>>', res.data.data)
                  if (res.data.status == "success") {
                    Swal.fire({
                      title: 'Submitted Successfully!',
                      text: 'One of our representative will get back to you.',
                      icon: 'success',
                      customClass: {
                        icon: 'custom-swal-icon' // Apply the custom class
                      },
                      width: '400px',
                      confirmButtonText: 'Ok'
                    });
                    setfullname("");
                    setemail("");
                    setnumber("");
                    setmessage("");
                  } else {
                    Swal.fire({
                      title: 'Not Submitted!!',
                      text: 'Something went wrong please try again',
                      icon: 'error',
                      customClass: {
                        icon: 'custom-swal-icon' // Apply the custom class
                      },
                      width: '400px',
                      confirmButtonText: 'Ok'
                    });
                  }
                }).catch((err) => {
                    setshowloading(false);
                  alert(err.message);
                });
                setshowloading(false);
            } else {
            alert('Fill all required details.')
          }
    }

    return (
      <>
        <Header />
        <div className="breadscrumbg">
          <div className="pagewidth">
            <p className="breadscrumb-pagetitle">Contact Us</p>
            <p className="breadscrumb-text">
              
            </p>
          </div>
        </div>
        <div className="home-section2 contact-section1">
            <div className="pagewidth">
                <div className="row">
                    <div className="col-md-6">
                        <p className="section-head about-who-we-are">Get In Touch</p>
                        <div className="contact-info-div">
                            <div className="contact-info-div1">
                                <FontAwesomeIcon icon={faFlagUsa} className="contacticons" />
                                <Link to={"tel:+1-9069346041"} className="contactnumber">USA: +1-9069346041</Link>
                            </div>
                            <div className="contact-info-div1">
                                <FontAwesomeIcon icon={faCanadianMapleLeaf} className="contacticons" />
                                <Link to={"tel:+1-3023199684"} className="contactnumber">CANADA: +1-3023199684</Link>
                            </div>
                            <div className="contact-info-div1">
                                <FontAwesomeIcon icon={faPhone} className="contacticons" />
                                <Link to={"tel:+918792667363"} className="contactnumber">INDIA: +91-8792667363</Link>
                            </div>
                            <div className="contact-info-div1">
                                <FontAwesomeIcon icon={faLocationDot} className="contacticons" />
                                <p className="contactnumber">2055, Limestone road,STE 200-C Wilmington, DE United States.</p>
                            </div>
                            <div className="contact-info-div1">
                                <FontAwesomeIcon icon={faLocationDot} className="contacticons" />
                                <p className="contactnumber">3rd Floor, #11, Nitin Plaza, 27th Main Rd, 1st Sector, HSR Layout, Bengaluru, Karnataka 560102 INDIA.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <p className="section-head about-who-we-are">Send Us Your Message/Query</p>
                        <div className="contact-form">
                                <div className="input-div">
                                    <input value={fullname} onChange={(t) => {setfullname(t.target.value)}} type="text" class="form-control contactinput"  placeholder="Enter Full Name *" name="name" />
                                </div>
                                <div className="input-div">
                                    <input value={number} onChange={(t) => {setnumber(t.target.value)}} type="number" class="form-control contactinput"  placeholder="Enter Phone Number *" name="name" />
                                </div>
                                <div className="input-div">
                                    <input value={email} onChange={(t) => {setemail(t.target.value)}} type="text" class="form-control contactinput"  placeholder="Enter Email *" name="name" />
                                </div>
                                <div className="input-div">
                                    <textarea value={message} onChange={(t) => {setmessage(t.target.value)}} type="text" rows={4} class="form-control contactinput textareainput"  placeholder="Message / Query" name="name" />
                                </div>
                                <div className="input-div">
                                    <button className="submit-btn" onClick={!showloading ? handlesubmitregister : null}>
                                    {
                    showloading ?
                    <Spinner color='#fff' size={15} className='spinnerCenter' /> : 
                    "Send"
                  }
                                    </button>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
      </>
    );
} 

export default ContactUs