import React from "react";
import { Route, Link, BrowserRouter as Router, Routes } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faUserCheck,
  faCalendar,
  faStar,
  faDesktop,
} from "@fortawesome/free-solid-svg-icons";

export const CourseCard = () => {
  return (
    <div className="course-card-body">
      <p className="course-card-title">
        Project Management Professional (PMP)® Certification Training
      </p>
      <div className="desc-maindiv">
        <div className="icondiv">
          <FontAwesomeIcon icon={faCircleCheck} className="iconstyles" />
        </div>
        <p className="course-card-desc">
          Scrum is a growing Agile framework that has been gaining momentum in
          organizations by improving productivity and innovation,...
        </p>
      </div>
      <div className="features-div">
        <div className="row">
          <div className="col-md-6">
            <div className="features1-div">
              <FontAwesomeIcon icon={faUserCheck} className="iconstyles" />
              <p className="userchecktext">2400+ Enrolled Already</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="features1-div">
              <FontAwesomeIcon icon={faCalendar} className="iconstyles" />
              <p className="userchecktext">4 Days Course</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="features1-div">
              <FontAwesomeIcon icon={faStar} className="iconstyles" />
              <p className="userchecktext">4.8 / 5</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="features1-div">
              <FontAwesomeIcon icon={faDesktop} className="iconstyles" />
              <p className="userchecktext">Live Online Class</p>
            </div>
          </div>
        </div>
      </div>
      <hr className="course-card-hr" />
      <div className="banner-btn-div">
        <Link to={"/"} className="course-btn">
          <p>View Course</p>
        </Link>
        <Link to={"/"} className="course-btn course-btn1">
          <p>View Schedule</p>
        </Link>
      </div>
    </div>
  );
};
