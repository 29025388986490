import React, { useRef, useState, useEffect } from "react";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTie } from "@fortawesome/free-solid-svg-icons";
import thankyouimg from "../assets/payment/thankyou.png";
import { useNavigate, useLocation } from 'react-router-dom';

const ThankYou = () => {
  const navigate = useNavigate();
  const location = useLocation();


  useEffect(() => {
    window.history.pushState(null, null, '/thankyou');
    window.history.pushState(null, null, '/thankyou');
    window.history.go(-1);
  }, []);

  return (
    <div className="cartheader">
      <Header />
      <div className="course-details-topborder"></div>
      <div className="pagewidth">
        <div className="thankyousection">
          <div className="row">
            <div className="col-12 col-md-6">
              <p className="thankyouhead">Thank You for Enrolling!</p>
              <p className="thankyoudesc">We're thrilled to have you join our community of learners dedicated to advancing their skills and knowledge. Your enrollment is confirmed, and you're one step closer to achieving your professional goals.</p>
              <p className="thankyoudesc thankyoudesc-needhelp">Need Help ?</p>
              <p className="thankyoudesc">If you any questions or need assistance, our support team os here to help. Feel free to contact us at support@upskills.com or visit our Help Center.</p>
            </div>
            <div className="col-12 col-md-6">
                <img src={thankyouimg} className="thankyouimg" />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ThankYou;
